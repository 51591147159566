




































































































import BaseModel, { Form } from '../../../application/model/BaseModel'
import { Component, Ref } from 'vue-property-decorator'
import { QrcodeStream } from 'vue-qrcode-reader'
import { Action, Getter } from 'vuex-class'
import Hospital from '@/application/model/Hospital'
import Patient from '@/application/model/Patient'
import TooltipView from '@/components/common/TooltipView.vue'
import PatientVitalsSource from '@/application/model/PatientVitalsSource'
import {
  GETTER_SELECTED_HOSPITAL,
  NAMESPACE_PATIENTS,
  NAMESPACE_VITALS_SOURCES
} from '@/application/store/modules/selected/hospital/SelectedHospitalModule'
import {
  ACTION_ADMIT_PATIENT,
  ACTION_POST_PATIENT,
  ACTION_SEARCH_PATIENTS,
  GETTER_ADMITTED_PATIENTS,
  GETTER_SEARCHED_PATIENTS
} from '@/application/store/modules/selected/hospital/SelectedHospitalPatientsModule'
import PatientDataProvider from '@/application/model/PatientDataProvider'
import { GETTER_ALL_VITALS_SOURCES } from '@/application/store/modules/selected/hospital/SelectedHospitalVitalsSourcesModule'
import { State2Way } from 'vuex-class-state2way'
import HospitalPatient from '@/application/model/HospitalPatient'
import { ACTION_ACTIVATE_VITALS_SOURCE } from '@/application/store/modules/selected/patient/SelectedPatientVitalsSourcesModule'
import {
  buildDeepQualifiedStoreMethodPath,
  buildQualifiedStoreMethodPath,
  buildQualifiedStoreStatePath,
  NAMESPACE_SELECTED_HOSPITAL,
  NAMESPACE_SELECTED_PATIENT
} from '@/application/store/RootStore'
import {
  MUTATION_SELECTED_PATIENT,
  STATE_SELECTED_PATIENT
} from '@/application/store/modules/selected/patient/SelectedPatientModule'
import VitalsSource from '@/application/model/VitalsSource'

@Component({ components: { Tooltip: TooltipView, QrcodeStream } })
export default class AddPatientView extends BaseModel {
  @Action(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_PATIENTS), ACTION_SEARCH_PATIENTS))
  searchPatients!: (patient: Patient) => Promise<Array<Patient>>

  @Action(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_PATIENTS), ACTION_POST_PATIENT))
  postPatient!: (patient: Patient) => Promise<Patient>

  @Action(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_PATIENTS), ACTION_ADMIT_PATIENT))
  admitPatient!: (patientId: string) => Promise<HospitalPatient>

  @Action(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_PATIENT, NAMESPACE_VITALS_SOURCES), ACTION_ACTIVATE_VITALS_SOURCE))
  activateVitalsSource!: (vitalsSourceId: string) => Promise<PatientVitalsSource>

  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_HOSPITAL, GETTER_SELECTED_HOSPITAL))
  selectedHospital!: Hospital

  @Getter(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_VITALS_SOURCES), GETTER_ALL_VITALS_SOURCES))
  hospitalVitalsSources!: Array<VitalsSource>

  @Getter(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_PATIENTS), GETTER_ADMITTED_PATIENTS))
  admittedPatients!: Array<HospitalPatient>

  @Getter(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_PATIENTS), GETTER_SEARCHED_PATIENTS))
  searchedPatients!: Array<Patient>

  @State2Way(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_PATIENT, MUTATION_SELECTED_PATIENT),
    buildQualifiedStoreStatePath(NAMESPACE_SELECTED_PATIENT, STATE_SELECTED_PATIENT))
  selectedPatient!: HospitalPatient

  @Ref('FORM_REF')
  readonly form!: Form

  patient = new Patient()

  existingPatient: PatientVitalsSource | null = null

  vitalsSource = new VitalsSource()

  isDialogOpen = false

  isQrScanning = false

  isSearching = false

  searchIntervalId!: number

  searchCountDown!: number

  isSaving = false

  errorMessage = ''

  constructor () {
    super('patient')
  }

  get inactiveHospitalVitalsSources (): Array<VitalsSource> {
    return this.hospitalVitalsSources
      .filter(vitalsSource => !VitalsSource.isActive(vitalsSource))
  }

  initQrCodeScanner (promise: Promise<any>) {
    this.errorMessage = ''
    promise.catch((error: any) => {
      if (error.name === 'NotAllowedError') {
        this.errorMessage = 'ERROR: you need to grant camera access permisson'
      } else if (error.name === 'NotFoundError') {
        this.errorMessage = 'ERROR: no camera on this device'
      } else if (error.name === 'NotSupportedError') {
        this.errorMessage = 'ERROR: secure context required (HTTPS, localhost)'
      } else if (error.name === 'NotReadableError') {
        this.errorMessage = 'ERROR: is the camera already in use?'
      } else if (error.name === 'OverconstrainedError') {
        this.errorMessage = 'ERROR: installed cameras are not suitable'
      } else if (error.name === 'StreamApiNotSupportedError') {
        this.errorMessage = 'ERROR: Stream API is not supported in this browser'
      }
      this.isQrScanning = false
    })
  }

  public onQrCodeDecoded (decodedId: string) {
    this.vitalsSource.id = decodedId
    this.isQrScanning = false
  }

  setSearchCountdown () {
    if (this.isSearching) {
      return
    }
    this.isSearching = true
    this.searchCountDown = 2
    const searchIntervalId = setInterval(() => {
      this.searchCountDown = this.searchCountDown - 1
      if (this.searchCountDown === 0) {
        this.searchPatientsFromForm()
        clearInterval(searchIntervalId)
      }
    }, 1000)
  }

  searchPatientsFromForm (): void {
    if (!this.searchFieldsFilled()) {
      return
    }
    this.searchPatients(this.patient)
      .then(result => {
        this.isSearching = false
      })
  }

  searchFieldsFilled (): boolean {
    return (!!this.patient.dataProviderPatientId && this.patient.dataProviderPatientId.length > 0) ||
      (!!this.patient.fullName && this.patient.fullName.length > 0)
  }

  save () {
    if (this.form.validate()) {
      this.isSaving = true
      this.createAndAdmitPatient()
        .then(hospitalPatient => {
          this.activateVitalsSource(this.vitalsSource.id!)
        })
        .then(() => {
          this.resetForm()
        })
    }
  }

  createAndAdmitPatient (): Promise<HospitalPatient> {
    return this.getOrCreatePatient()
      .then(patient => this.admitPatient(patient.id!)
        .then(hospitalPatient => {
          hospitalPatient.patient = patient
          this.selectedPatient = hospitalPatient
          return hospitalPatient
        }))
  }

  getOrCreatePatient (): Promise<Patient> {
    if (!this.existingPatient) {
      const patientDataProvider = new PatientDataProvider()
      patientDataProvider.id = this.selectedHospital!.patientDataProvider!.id!
      const patient = new Patient()
      patient.fullName = this.getStringOrUndefined(this.patient.fullName)
      patient.dataProviderPatientId = this.getStringOrUndefined(this.patient.dataProviderPatientId)
      patient.dataProvider = patientDataProvider
      console.log('Creating new patient ', JSON.stringify(patient))
      return this.postPatient(patient)
    } else {
      console.log('Picking already existing patient to admit ', JSON.stringify(this.existingPatient))
      return new Promise<Patient>(() => this.existingPatient)
    }
  }

  getStringOrUndefined (input ?: string): string | undefined {
    if (!input || input.length === 0) {
      return undefined
    } else {
      return input
    }
  }

  public resetForm () {
    this.isDialogOpen = false
    this.isSaving = false
  }
}
