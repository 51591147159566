

















































import { Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import BaseModel from '../../../application/model/BaseModel'
import AdmitPatient from '@/components/hospital/patients/AdmitPatientView.vue'
import {
  ACTION_FETCH_ADMITTED_PATIENTS,
  GETTER_ADMITTED_PATIENTS
} from '@/application/store/modules/selected/hospital/SelectedHospitalPatientsModule'
import Patient from '@/application/model/Patient'
import {
  ACTION_SET_SELECTED_PATIENT,
  GETTER_SELECTED_PATIENT
} from '@/application/store/modules/selected/patient/SelectedPatientModule'
import HospitalPatient from '@/application/model/HospitalPatient'
import {
  buildDeepQualifiedStoreMethodPath,
  buildQualifiedStoreMethodPath,
  NAMESPACE_SELECTED_HOSPITAL,
  NAMESPACE_SELECTED_PATIENT
} from '@/application/store/RootStore'
import { NAMESPACE_PATIENTS } from '@/application/store/modules/selected/hospital/SelectedHospitalModule'
import PatientVitalSignsView from '@/components/hospital/patients/vitalsigns/PatientVitalSignsView.vue'

@Component({
  components: {
    PatientVitalSigns: PatientVitalSignsView,
    AdmitPatient
  }
})

export default class AdmittedPatients extends BaseModel {
  @Action(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_PATIENTS), ACTION_FETCH_ADMITTED_PATIENTS))
  fetchAdmittedPatients!: () => Promise<Array<HospitalPatient>>

  @Getter(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_PATIENTS), GETTER_ADMITTED_PATIENTS))
  admittedPatients!: Array<HospitalPatient>

  @Action(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_PATIENT, ACTION_SET_SELECTED_PATIENT))
  setSelectedPatient!: (newPatient: HospitalPatient | null) => Promise<Array<Patient>>

  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_PATIENT, GETTER_SELECTED_PATIENT))
  getSelectedPatient!: HospitalPatient | null

  searchPattern = ''

  constructor () {
    super('patient')
  }

  get selectedPatient (): HospitalPatient | null {
    return this.getSelectedPatient
  }

  set selectedPatient (newPatient: HospitalPatient | null) {
    this.setSelectedPatient(newPatient)
  }

  get filteredPatients (): Array<HospitalPatient> {
    if (this.searchPattern.length === 0) {
      return this.admittedPatients
    }
    return this.admittedPatients.filter(hospitalPatient => {
      return hospitalPatient.patient!.fullName!.toLowerCase().trim()
        .includes(this.searchPattern.toLowerCase().trim())
    })
  }

  get useNewGraph () {
    return {} // this.$store.state.appSettings.useNewGraph
  }

  get heartbeat () {
    return {} // this.$store.state.vitals.map((v: Vital) => v.pulseRate)
  }

  get oxygenSaturation () {
    return {} // this.$store.state.vitals.map((v: Vital) => v.oxygenSaturation)
  }

  get bodyTemperature () {
    return {} // this.$store.state.vitals.map((v: Vital) => v.bodyTemperature)
  }

  async mounted (): Promise<void> {
    await this.fetchAdmittedPatients()
    this.initSelectedItem()
  }

  /**
   * Cause the fetch actions to trigger twice, but otherwise the selected item is not retained on refresh
   * Maybe a better solution can be found here
   */
  initSelectedItem (): void {
    if (!this.selectedPatient) {
      return
    }
    this.selectedPatient = this.admittedPatients.filter(patient => this!.selectedPatient!.patient!.id === patient!.patient!.id)[0]
  }
}

export interface MenuItem {
  first: { label: string; value: string };

  second: { label: string; value: string };
}
