


















import { Component } from 'vue-property-decorator'
import BaseModel from '../../../../application/model/BaseModel'
import PatientVitalsSource from '@/application/model/PatientVitalsSource'
import { Getter } from 'vuex-class'
import {
  GETTER_SELECTED_PATIENT,
  NAMESPACE_VITALS_SOURCES
} from '@/application/store/modules/selected/patient/SelectedPatientModule'
import {
  buildDeepQualifiedStoreMethodPath,
  buildQualifiedStoreMethodPath,
  NAMESPACE_SELECTED_PATIENT
} from '@/application/store/RootStore'
import HospitalPatient from '@/application/model/HospitalPatient'
import { GETTER_ALL_VITALS_SOURCES } from '@/application/store/modules/selected/patient/SelectedPatientVitalsSourcesModule'
import VitalSignsChartView from '@/components/hospital/patients/vitalsigns/VitalSignsChartView.vue'

@Component({
  components: { VitalSignsChartView }
})
export default class PatientVitalSignsView extends BaseModel {
  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_PATIENT, GETTER_SELECTED_PATIENT))
  selectedPatient!: HospitalPatient

  @Getter(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_PATIENT, NAMESPACE_VITALS_SOURCES), GETTER_ALL_VITALS_SOURCES))
  patientVitalsSources!: Array<PatientVitalsSource>

  constructor () {
    super('vitals-source')
  }
}
