import { VitalSignType } from '@/application/model/VitalSignType'

export class VitalsThreshold {
  id?: string

  vitalSignType!: VitalSignType

  minValue!: number

  maxValue!: number

  constructor (vitalSignType: VitalSignType, minValue: number, maxValue: number) {
    this.vitalSignType = vitalSignType
    this.minValue = minValue
    this.maxValue = maxValue
  }
}

export default class Thresholds {
  private global = new Array<VitalsThreshold>();

  constructor () {
    this.global.push({ vitalSignType: VitalSignType.PULSE_RATE, minValue: 40, maxValue: 50 })
    this.global.push({ vitalSignType: VitalSignType.OXYGEN_SATURATION, minValue: 95, maxValue: 100 })
    this.global.push({ vitalSignType: VitalSignType.BODY_TEMPERATURE, minValue: 36, maxValue: 38 })
  }

  getThreshold (type: VitalSignType): VitalsThreshold {
    return this.global.find((t: VitalsThreshold) => t.vitalSignType === type)!
  }

  isReachedThreshold (type: VitalSignType, value: number): boolean {
    const threshold = this.getThreshold(type)
    return this.isReachedLowerThreshold(type, value) ||
      this.isReachedHigherThreshold(type, value)
  }

  isReachedLowerThreshold (type: VitalSignType, value: number): boolean {
    const threshold = this.getThreshold(type)
    if (!threshold.minValue) {
      return false
    }
    return value < threshold.minValue
  }

  isReachedHigherThreshold (type: VitalSignType, value: number): boolean {
    const threshold = this.getThreshold(type)
    if (!threshold.maxValue) {
      return false
    }
    return value > threshold.maxValue
  }
}
