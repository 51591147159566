import VitalSign from '@/application/model/VitalSign'
import { VitalsThreshold } from '@/application/model/Thresholds'
import { VitalSignType } from '@/application/model/VitalSignType'
import {
  OXYGEN_SATURATION_CHART_MAX,
  OXYGEN_SATURATION_CHART_MIN,
  OXYGEN_SATURATION_CHART_TICK
} from '@/application/model/OxygenSaturation'
import { PULSE_RATE_CHART_MAX, PULSE_RATE_CHART_MIN, PULSE_RATE_CHART_TICK } from '@/application/model/PulseRate'
import { BODY_TEMPERATURE_MAX, BODY_TEMPERATURE_MIN, BODY_TEMPERATURE_TICK } from '@/application/model/BodyTemperature'

class XAxis {
  type = 'datetime'

  range = 6000

  labels = {
    show: false,
    datetimeUTC: false
  }
}

function getChartMin (vitalSignType: VitalSignType): number {
  switch (vitalSignType) {
    case VitalSignType.OXYGEN_SATURATION:
    {
      return OXYGEN_SATURATION_CHART_MIN
    }
    case VitalSignType.PULSE_RATE:
    {
      return PULSE_RATE_CHART_MIN
    }
    case VitalSignType.BODY_TEMPERATURE:
    {
      return BODY_TEMPERATURE_MIN
    }
  }
}

function getChartMax (vitalSignType: VitalSignType): number {
  switch (vitalSignType) {
    case VitalSignType.OXYGEN_SATURATION:
    {
      return OXYGEN_SATURATION_CHART_MAX
    }
    case VitalSignType.PULSE_RATE:
    {
      return PULSE_RATE_CHART_MAX
    }
    case VitalSignType.BODY_TEMPERATURE:
    {
      return BODY_TEMPERATURE_MAX
    }
  }
}

function getChartTick (vitalSignType: VitalSignType): number {
  switch (vitalSignType) {
    case VitalSignType.OXYGEN_SATURATION:
    {
      return OXYGEN_SATURATION_CHART_TICK
    }
    case VitalSignType.PULSE_RATE:
    {
      return PULSE_RATE_CHART_TICK
    }
    case VitalSignType.BODY_TEMPERATURE:
    {
      return BODY_TEMPERATURE_TICK
    }
  }
}

class YAxis {
  tickAmount!: number

  min!: number

  max!: number

  constructor (vitalSignType: VitalSignType) {
    this.min = getChartMin(vitalSignType)
    this.max = getChartMax(vitalSignType)
    this.tickAmount = getChartTick(vitalSignType)
  }
}

class AnnotationYAxis {
  y!: number
  y2!: number
  borderColor = 'red'
  fillColor = 'red'

  constructor (from: number, to: number) {
    this.y = from
    this.y2 = to
  }
}

class DynamicAnimation {
  speed!: number

  constructor (speed: number) {
    this.speed = speed
  }
}

class Animations {
  enabled = true
  easing = 'linear'
  dynamicAnimation!: DynamicAnimation

  constructor (dynamicAnimation: DynamicAnimation) {
    this.dynamicAnimation = dynamicAnimation
  }
}

class Chart {
  id = 'realtime'

  type = 'line'

  animations!: Animations

  toolbar = {
    show: false
  }

  zoom = {
    enabled: false
  }

  constructor (dataRate: number) {
    this.animations = new Animations(new DynamicAnimation(dataRate))
  }
}

export class ChartOptions {
  chart: Chart

  xaxis!: XAxis

  yaxis!: YAxis

  dataLabels = {
    enabled: false
  }

  stroke = {
    curve: 'smooth',
    lineCap: 'round'
  }

  markers = {
    size: 0
  }

  legend = {
    show: false
  }

  annotations!: any

  constructor (dataRate: number, vitalsThreshold: VitalsThreshold) {
    this.chart = new Chart(dataRate)
    this.xaxis = new XAxis()
    this.yaxis = new YAxis(vitalsThreshold.vitalSignType)

    if (vitalsThreshold.vitalSignType === VitalSignType.OXYGEN_SATURATION) {
      const yAxisLowerBounds = new AnnotationYAxis(getChartMin(vitalsThreshold.vitalSignType), vitalsThreshold.minValue * 100)
      const yAxisUpperBounds = new AnnotationYAxis(getChartMax(vitalsThreshold.vitalSignType), vitalsThreshold.maxValue * 100)
      this.annotations = {
        yaxis: [yAxisLowerBounds, yAxisUpperBounds]
      }
    } else {
      const yAxisLowerBounds = new AnnotationYAxis(getChartMin(vitalsThreshold.vitalSignType), vitalsThreshold.minValue)
      const yAxisUpperBounds = new AnnotationYAxis(getChartMax(vitalsThreshold.vitalSignType), vitalsThreshold.maxValue)
      this.annotations = {
        yaxis: [yAxisLowerBounds, yAxisUpperBounds]
      }
    }
  }
}

export class SeriesDataEntry {
  x!: number

  y!: number

  constructor (x: number, y: number) {
    this.x = x
    this.y = y
  }

  public static fromVitalSign (vitalSign: VitalSign): SeriesDataEntry {
    return new SeriesDataEntry(vitalSign.timestamp.valueOf(), VitalSign.convertVitalSignValueForCharts(vitalSign))
  }
}

export class Series {
  data!: Array<SeriesDataEntry>

  constructor (data: Array<SeriesDataEntry>) {
    this.data = data
  }
}
