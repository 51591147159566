






























import BaseModel from '../../../../application/model/BaseModel'
import { Component, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import AppSettings from '@/application/model/AppSettings'
import VitalSign from '@/application/model/VitalSign'
import { VitalSignType } from '@/application/model/VitalSignType'
import { VitalsThreshold } from '@/application/model/Thresholds'
import { PulseRate } from '@/application/model/PulseRate'
import { BodyTemperature } from '@/application/model/BodyTemperature'
import { OxygenSaturation } from '@/application/model/OxygenSaturation'
import {
  buildDeepQualifiedStoreMethodPath,
  buildQualifiedStoreMethodPath,
  NAMESPACE_SELECTED_HOSPITAL,
  NAMESPACE_SELECTED_PATIENT,
  NAMESPACE_USER_CONTEXT
} from '@/application/store/RootStore'
import {
  ACTION_FETCH_ALL_VITALS_THRESHOLDS,
  GETTER_ALL_VITALS_THRESHOLDS
} from '@/application/store/modules/selected/hospital/SelectedHospitalVitalsThresholdsModule'
import { GETTER_APP_SETTINGS } from '@/application/store/modules/UserContextModule'
import {
  ACTION_FETCH_VITAL_SIGNS_BY_TYPE,
  GETTER_BODY_TEMPERATURES,
  GETTER_OXYGEN_SATURATIONS,
  GETTER_PULSE_RATES,
  POLL_RATE
} from '@/application/store/modules/selected/patient/SelectedPatientVitalSignsModule'
import { NAMESPACE_VITALS_THRESHOLDS } from '@/application/store/modules/selected/hospital/SelectedHospitalModule'
import { ChartOptions, Series, SeriesDataEntry } from '../../../model/ApexModel'
import {
  GETTER_SELECTED_PATIENT,
  NAMESPACE_VITAL_SIGNS
} from '@/application/store/modules/selected/patient/SelectedPatientModule'
import HospitalPatient from '@/application/model/HospitalPatient'

@Component
export default class VitalSignsChartView extends BaseModel {
  @Prop({ required: true })
  vitalSignType!: VitalSignType

  @Action(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_VITALS_THRESHOLDS), ACTION_FETCH_ALL_VITALS_THRESHOLDS))
  fetchHospitalVitalsThresholds!: () => Promise<Array<VitalsThreshold>>

  @Action(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_PATIENT, NAMESPACE_VITALS_THRESHOLDS), ACTION_FETCH_ALL_VITALS_THRESHOLDS))
  fetchPatientVitalsThresholds!: () => Promise<Array<VitalsThreshold>>

  @Action(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_PATIENT, NAMESPACE_VITAL_SIGNS), ACTION_FETCH_VITAL_SIGNS_BY_TYPE))
  fetchVitalSigns!: (vitalSignType: VitalSignType) => Promise<EventSource>

  @Getter(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_PATIENT, NAMESPACE_VITAL_SIGNS), GETTER_PULSE_RATES))
  pulseRates!: Array<PulseRate>

  @Getter(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_PATIENT, NAMESPACE_VITAL_SIGNS), GETTER_BODY_TEMPERATURES))
  bodyTemperatures!: Array<BodyTemperature>

  @Getter(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_PATIENT, NAMESPACE_VITAL_SIGNS), GETTER_OXYGEN_SATURATIONS))
  oxygenSaturations!: Array<OxygenSaturation>

  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_USER_CONTEXT, GETTER_APP_SETTINGS))
  appSettings!: AppSettings

  @Getter(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_VITALS_THRESHOLDS), GETTER_ALL_VITALS_THRESHOLDS))
  hospitalVitalsThresholds!: Array<VitalsThreshold>

  @Getter(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_PATIENT, NAMESPACE_VITALS_THRESHOLDS), GETTER_ALL_VITALS_THRESHOLDS))
  patientVitalsThresholds!: Array<VitalsThreshold>

  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_PATIENT, GETTER_SELECTED_PATIENT))
  selectedPatient!: HospitalPatient

  vitalSignsEventSource?: EventSource = undefined

  hasDanger = false

  constructor () {
    super('vital-sign')
  }

  async mounted (): Promise<void> {
    await this.fetchHospitalVitalsThresholds()
    await this.fetchPatientVitalsThresholds()
    this.createVitalSignsEventSource()
    this.$store.watch(
      (state) => {
        return this.selectedPatient
      },
      () => {
        this.closeVitalSignsEventSource()
        this.createVitalSignsEventSource()
      }
    )
  }

  beforeDestroy (): void {
    this.closeVitalSignsEventSource()
  }

  get chartOptions (): ChartOptions {
    const vitalsThreshold = this.getVitalsThreshold()
    return new ChartOptions(POLL_RATE, vitalsThreshold)
  }

  get series (): Array<Series> {
    const vitalSigns = this.getVitalSigns()
    if (!vitalSigns) {
      return []
    }
    const data: Array<SeriesDataEntry> = vitalSigns
      .map(vitalSign => SeriesDataEntry.fromVitalSign(vitalSign))
    return [new Series(data)]
  }

  getVitalsThreshold (): VitalsThreshold {
    const vitalsThresholds = this.getVitalsThresholds()
      .filter(threshold => threshold.vitalSignType === this.vitalSignType)
    if (vitalsThresholds.length === 0) {
      return new VitalsThreshold(this.vitalSignType, 0, 100)
    } else {
      return vitalsThresholds[0]
    }
  }

  getVitalsThresholds (): Array<VitalsThreshold> {
    if (!this.patientVitalsThresholds || this.patientVitalsThresholds.length === 0) {
      return this.hospitalVitalsThresholds
    } else {
      return this.patientVitalsThresholds
    }
  }

  getVitalSigns (): Array<VitalSign> {
    if (this.vitalSignType === VitalSignType.OXYGEN_SATURATION) {
      return this.oxygenSaturations
    } else if (this.vitalSignType === VitalSignType.PULSE_RATE) {
      return this.pulseRates
    } else if (this.vitalSignType === VitalSignType.BODY_TEMPERATURE) {
      return this.bodyTemperatures
    }
    throw new Error('Unknown vital sign type ' + this.vitalSignType)
  }

  get status (): number | string {
    const vitalSign: VitalSign | null = this.getLatestVitalSign()
    if (vitalSign === null) {
      this.hasDanger = false
      return ''
    } else {
      this.updateDanger(vitalSign)
      return VitalSign.convertVitalSignValueForCharts(vitalSign)
    }
  }

  getLatestVitalSign (): VitalSign | null {
    const vitalSigns: Array<VitalSign> = this.getVitalSigns()
    if (vitalSigns.length === 0) {
      return null
    } else {
      return vitalSigns[vitalSigns.length - 1]
    }
  }

  updateDanger (vitalSign: VitalSign): void {
    const threshold: VitalsThreshold = this.getVitalsThreshold()
    if (vitalSign.value > threshold.maxValue || vitalSign.value < threshold.minValue) {
      this.hasDanger = true
    } else {
      this.hasDanger = false
    }
  }

  async createVitalSignsEventSource (): Promise<void> {
    console.log('Creating connection to vital signs backend')
    if (this.vitalSignsEventSource) {
      this.closeVitalSignsEventSource()
    }
    await this.fetchVitalSigns(this.vitalSignType)
      .then(eventSource => {
        this.vitalSignsEventSource = eventSource
      })
  }

  closeVitalSignsEventSource (): void {
    if (this.vitalSignsEventSource) {
      console.log('Stopped requesting ' + this.vitalSignType)
      this.vitalSignsEventSource.close()
    }
  }
}

