import PatientDataProvider from '@/application/model/PatientDataProvider'
import PatientVitalsSource from '@/application/model/PatientVitalsSource'

export default class Patient {
  id?: string

  dataProviderPatientId?: string

  fullName?: string

  dataProvider?: PatientDataProvider

  patientVitalsSources?: Array<PatientVitalsSource>
}
